import React, { RefObject }  from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import { LoadingWidget } from "../Components/LoadingWidget";
import { DataHelper } from "../Helpers/DataHelper";
import { ChartDataModel } from "../Models/ChartDataModel";
import { ErrorWindow } from "../Components/ErrorWindow";

export class UploadsPerUser extends React.Component<
    {
        dataClient: DataHelper,
        studyId: string
    },
    {
        userUploadData: ChartDataModel[],
        isBusy: boolean
    }
> {

    // Data structure: 
    // data = [
    //     {
    //         name: "category",
    //         value: number
    //     }
    // ];

    errorWindow:  RefObject<ErrorWindow>;
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.state = {
            userUploadData: [],
            isBusy: false
        }
    }

    getData = (studyId: string): void => {
        this.setState({ isBusy: true })
        this.props.dataClient.CallDashboardService("GetUploadsPerUser", {}, {studyId: this.props.studyId}, (result: string) => {
            let userUpload = JSON.parse(result);
            this.setState({ userUploadData: userUpload, isBusy: false })
        }, (stautus:number, errorMessage:string)=>{
            (this.errorWindow.current as ErrorWindow).show(stautus, errorMessage)}, "GET", false)}

    componentDidMount(): void {}

    render() {

        return (
            <>
            <ErrorWindow ref={this.errorWindow}/>
            <h3>Uploads per user</h3>
            <div className="chartHolderStreach">
            
                <BarChart
                    width={900}
                    height={300}
                    data={this.state.userUploadData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 100,
                        bottom: 5
                    }}
                    layout="vertical"
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number"/>
                    <YAxis dataKey="name" type="category"/>
                    <Tooltip />
                    <Legend />
                    <Bar name="No. of uploads" dataKey="value1" fill="#8884d8" />
                </BarChart>
                <LoadingWidget visible={this.state.isBusy} zIndexLayer={0}></LoadingWidget>
                </div>
            </>
        );
    }
}
