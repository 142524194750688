import React from "react";
import { DataHelper } from "../Helpers/DataHelper";
import { PreviewMeasurement } from "../Models/PreviewMeasurement";
import ChartD3 from "./ChartD3";
import { StudyModel } from "../Models/StudyModel";
import { LoadingWidget } from "./LoadingWidget";
import DragBar from "./DragBar";
import TTFMTileHolder from "./TTFMTileHolder";

export class LargeTTFMCurve extends React.Component<
    {
        subType: string,
        studySelected: StudyModel,
        dataReceiver: DataHelper, 
        measurementPreview: PreviewMeasurement,
        procedureId: string,
        width: number, 
        height: number,
        usefilling: boolean
        color: string, 
        color1: string, 
        color2: string, 
        color3: string, 
        includeScrubber: boolean,
        yAxisVisible: boolean,
        xAxisVisible: boolean,
        parentGroup: string,
        xAxisMax: number,
        meanCutOff1: number, 
        meanCutOff2: number, 
    },
    {
        tileMode: string,
        axisSize: number,
        cursorPosition: number, 
        dataLoaded: boolean,
        errorMessage: string,
        phasicity: string
    }
> {

 
    currentData: any = null;
    holderDiv: React.RefObject<HTMLDivElement>;
    ttfmChartd3: React.RefObject<ChartD3>;
    tilelimits: any;
    ttfmTileHolder: React.RefObject<TTFMTileHolder>;
    constructor(props: any) {
        super(props);
        this.holderDiv = React.createRef();
        this.ttfmChartd3 = React.createRef();
        this.ttfmTileHolder = React.createRef();
        this.state = {
            tileMode: "Gauges", 
            axisSize: 1000,
            cursorPosition: 1000,
            dataLoaded: false,
            errorMessage: "",
            phasicity: ""

        }
        this.tilelimits  ={
            ACI: {
           worstRange:{
                          min: 0,
                          max: 20
                      },
                          badRange:{
                          min: 20,
                          max: 50
                      },
                          goodRange:{
                          min: 50,
                          max: 102
                      } 
            },
           
              backFlow: {
                goodRange: {
                  min: -2,
                  max: 3
              },
              badRange: {
                  min: 3,
                  max: 5
              },
              worstRange: {
                  min: 5,
                  max: 50
              }
            },
            meanFlow: {
              worstRange:{
                min: 0,
                max: props.meanCutOff1
            },
                badRange:{
                min: props.meanCutOff1,
                max: props.meanCutOff2
            },
                goodRange:{
                min: props.meanCutOff2,
                max: 100
            }},
            pulsalityIndex:
            { goodRange: {
              min: -3,
              max: 3
          },
      badRange: {
              min: 3,
              max: 5
          },
      worstRange: {
              min: 5,
              max: 100
          }}, 
            diastolicFilling: 
            {
              goodRange: {
                min: 60,
                max: 110
            },
      badRange: {
                min: 30,
                max: 60
            },
      worstRange: {
                min: 0,
                max: 30
            }
            },
            MAP: { goodRange:{
              min: 60,
              max: 200
          },
              badRange:{
              min: 0,
              max: 60
          },
              worstRange:{
              min: 0,
              max: 0
          }}
        }
    }

    addDataPoints = (dataToRenderAll: any, compareMode = false, scrubberPos = 0) =>
    {
        let colorPalette = [this.props.color1, this.props.color3, "var(--Raven)", "var(--GreenApple)", "var(--Crocodile)", "var(--BananaClan)", "var(--Bluejay)", "var(--FireEngineRed)"]
        let MainKey = "BLUE";
        let reservedKeys = ["Phase", "PhaseFull", "ECG", "PI", "ACI", "x"];
      let ttfmChartd3: ChartD3 = (this.ttfmChartd3.current as ChartD3);
      if (!ttfmChartd3)
        return;
   
      let xAxisSize = 1000;
      let yAxisMin = 0;
      let yAxisMax = 0;

      if (dataToRenderAll)
      {

          let reservedKeys = ["Phase", "PhaseFull", "ECG", "PI", "ACI", "x"];

          for (let dataProp in dataToRenderAll) {
              if (reservedKeys.indexOf(dataProp) === -1)
              {
              const dataValues = dataToRenderAll[dataProp];
              if (dataValues && dataValues.length > 0) {
                  for (let index = 0; index < dataValues.length; index++) {
                      const element = dataValues[index];
                      if (yAxisMin > element)
                      {
                          yAxisMin = element;
                      }
                      if (yAxisMax < element)
                      {
                          yAxisMax = element;
                      }
                      
                  }
              }
              }

          }
      }

      ttfmChartd3.resetDrawingArea(null, null, yAxisMin, yAxisMax, xAxisSize, 0, 0 );
      let seriesIndex = 0;
      for (let dataProp in dataToRenderAll) {
          if (reservedKeys.indexOf(dataProp) === -1)
          {
          const dataValues = dataToRenderAll[dataProp];
          if (dataValues && dataValues.length > 0) {
              if (dataProp === MainKey)
              {
            if (this.props.usefilling){ //&& dataToRenderAll["Phase"] &&dataToRenderAll["Phase"].length > 0) {

             
            
                if (dataToRenderAll["PhaseFull"] && dataToRenderAll["PhaseFull"].length > 0 )
                {
                    //First draw a filled line with no change in phase and mark the items added with their x value.
                ttfmChartd3.addAreaData(dataToRenderAll[dataProp], this.props.color1, "TTFM", dataToRenderAll["x"], compareMode, scrubberPos);
                ttfmChartd3.setAreaColor(dataToRenderAll["PhaseFull"], this.props.color1, this.props.color2);
                }
else if (dataToRenderAll["Phase"].length > 0 )
{

                var phaseMarker = -1;
                var lastDrawn = 0;
                for (let index = 0; index < dataToRenderAll["Phase"].length; index++) {
                    const element = dataToRenderAll["Phase"][index];
                    if (element !== phaseMarker) {
                        if (phaseMarker === -1) {
                            phaseMarker = element;
                        } else {
                            ttfmChartd3.addAreaData(dataToRenderAll[dataProp].slice(lastDrawn, index), ((phaseMarker === 1)
                                ? this.props.color1
                                : this.props.color2), "TTFM", dataToRenderAll["x"].slice(lastDrawn, index), compareMode, scrubberPos);
                            phaseMarker = element;
                          //  datapointsaddedblue = datapointsaddedblue - lastDrawn + index;
                            lastDrawn = index;
                        }
                    }
    
                }
                if (lastDrawn < dataToRenderAll[dataProp].length) {
                    //If it wasn't drawn all the way to the end the draw the last bit.
                    ttfmChartd3.addAreaData(dataToRenderAll[dataProp].slice(lastDrawn, dataToRenderAll[dataProp].length), ((phaseMarker === 1)
                        ? this.props.color1
                        : this.props.color2), "TTFM", dataToRenderAll["x"].slice(lastDrawn, dataToRenderAll[dataProp].length), compareMode, scrubberPos);
                   // datapointsaddedblue = datapointsaddedblue - lastDrawn + dataToRenderBlue.length;
                }

            }else
            {
                ttfmChartd3.addAreaData(dataToRenderAll[dataProp], this.props.color1, "TTFM", dataToRenderAll["x"], compareMode, scrubberPos);
            }
                
            }else
            {
                //Override the first color to something darker when there is no filling.
                ttfmChartd3.addLineData(dataToRenderAll[dataProp], this.props.color1 === "var(--WaterySea)" ? "var(--TTFMPurple2)" : this.props.color1, "TTFM", dataToRenderAll["x"], compareMode, scrubberPos);
            }
        }else{

            let colorToUse = colorPalette[seriesIndex];
            if (dataProp === "RED")
                colorToUse = colorPalette[1];
            ttfmChartd3.addLineData(dataToRenderAll[dataProp],colorToUse, dataProp, dataToRenderAll["x"], compareMode, scrubberPos);

        }

          }
          seriesIndex++;
        }
        
   
      }
    }

    adjustScrubber = (value: number) =>
    {
       //this.state.cursorPosition = value;
       this.setState({ cursorPosition: value}, ()=> {this.addDataPoints(this.currentData, true, value); })
        
    }
    
    formatXAxisLabels = (value: number) =>
    {
        return "";
        //return value/200 + "s";
    }

     static defaultProps ={
        subType: "Flow",
        width: 880,
        height: 560,
        usefilling: true,
        includeScrubber: false,
        color: "black",
        color1: "var(--WaterySea)",
        color2: "var(--SassySalmon)",
        color3: "var(--Lacustral)",
        yAxisVisible: true,
        xAxisVisible: true,
        parentGroup: "",
        xAxisMax: 1100
     }

     onVisible = (element: Element, callback: (e:Element) => void) => {
        new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if(entry.intersectionRatio > 0) {
              callback(element);
              observer.disconnect();
            }
          });
        }).observe(element);
    }
    
    refreshData = ()=>
    {
        if (this.props.measurementPreview.id && !this.currentData)
        {
            // this.setState({
            //     dataLoaded: false
            // });
            this.props.dataReceiver.CallService("MiscData", "GetFlowData", {}, { studyId: this.props.studySelected.id, measurementId: this.props.measurementPreview.id, procedureId: this.props.procedureId, measurementType: this.props.subType, tokenId: this.props.dataReceiver.miscToken},  (measurementDataRaw: string)=>{
            this.currentData = {};
            if (measurementDataRaw)
            {
            this.currentData = JSON.parse(measurementDataRaw);
     
            this.addDataPoints( this.currentData, true, this.currentData["x"].length);
            this.setState({
                axisSize: this.currentData["x"].length,
                cursorPosition: this.currentData["x"].length      ,
                dataLoaded: true  
             });
            }else
            {
                this.setState({
                    errorMessage: "No flow measurement data found.",
                    dataLoaded: true
                 });
            }
        }, (errorVal: any) =>
        {
            this.setState({
                errorMessage: "Unable to load measurement",
                dataLoaded: true
             });
            console.log("Error loading thumbnail data: " + errorVal.message)
        }, "GET", false);


        this.props.dataReceiver.CallService("MiscData", "GetFlowPhasicity", {}, { studyId: this.props.studySelected.id, measurementId: this.props.measurementPreview.id, procedureId: this.props.procedureId, measurementType: this.props.subType, tokenId: this.props.dataReceiver.miscToken},  (measurementDataRaw: string)=>{

            this.setState({
                phasicity: measurementDataRaw
             });
           
        }, (errorVal: any) =>
        {
            // this.setState({
            //     errorMessage: "Unable to load measurement",
            //     dataLoaded: true
            //  });
            console.log("Error loading thumbnail phasicity: " + errorVal.message)
        }, "GET", false);

        }
    }


    componentDidMount(): void {
        if (this.holderDiv.current)
        this.onVisible(this.holderDiv.current, (e: Element) =>
        {
            this.refreshData();
        });

      
    }
    

    render() {
       
        
        return (
            <>
               <div className="thumbnail-container" ref={this.holderDiv}>
               {
                    this.state.errorMessage ?
                    <div className="thumbnailError">{this.state.errorMessage}</div>
                    :
                    <>           
                <LoadingWidget visible={!this.state.dataLoaded} zIndexLayer={0}></LoadingWidget>
                {this.props.subType === "Flow"
                ?<TTFMTileHolder ref={this.ttfmTileHolder} tileLimits={this.tilelimits} ttfmCurves={1} overrideName={this.props.procedureId} context="TTFM" MAP={this.props.measurementPreview.mAPMean ? this.props.measurementPreview.mAPMean as number : "--"} ACI={this.props.measurementPreview.aCIMean ? this.props.measurementPreview.aCIMean : "--"} mapStale={false} usefilling={true} BeatsPerMinute={this.props.measurementPreview.bPMMean as number ? this.props.measurementPreview.bPMMean as number : "--"} tileMode={this.state.tileMode} tilesExpanded={true} tileClicked={()=>{ 
                    this.setState({
                    tileMode: this.state.tileMode === "Tiles" ? "Gauges" : "Tiles"
                }, ()=>{
                    (this.ttfmTileHolder.current as TTFMTileHolder).performResize();
                });
                }} tileValueChanged={()=>{}} PulsalityIndex={this.props.measurementPreview.pI as number} MeanFlow={Math.abs(this.props.measurementPreview.mean as number)  *1000}  BackFlow={this.props.measurementPreview.insuf ? this.props.measurementPreview.insuf as number : "--"} DiastolicFilling={this.props.measurementPreview.dF ? this.props.measurementPreview.dF as number : "--"} ></TTFMTileHolder>
            :<></>}
                
                  <ChartD3
                    ref={this.ttfmChartd3}
                    id={"LARGEFLOWDIAG"+this.props.parentGroup+this.props.measurementPreview.id}
                    autoscroll={true}
                    width={this.props.width}
                    height={this.props.height}
                    yaxismin={0}
                    yaxismax={100}
                    xaxisposition={0}
                    xaxismax={this.props.xAxisMax}
                    yAxisVisible={this.props.yAxisVisible}
                    xAxisVisible={this.props.xAxisVisible}
                    color="var(--WaterySea)"
                    colorOutline="var(--dolphin)"
                    yAxisLabel={this.props.subType === "Flow" ? "ml/mm" : (this.props.subType === "PWDopplers" ? "cm/s" : "")}
                    margintop={5}
                    marginleft={20}
                    marginright={5}
                    marginbottom={50}
                    yAxisMinimal={false}
                    ></ChartD3>
                     <DragBar name={"compareTTFMSlider"+this.props.measurementPreview.flowId} valueWidth={this.props.xAxisMax}   xAxisLabelsFormat={this.formatXAxisLabels} value={ this.state.cursorPosition} max={this.state.axisSize} min={0} onChange={this.adjustScrubber}  height={80} width={this.props.width - 20}></DragBar> 
                     <div><span>Phasicity: </span><span>{this.state.phasicity}</span></div>
                     </>
                }
                </div>
            </>
        );
    }
}
