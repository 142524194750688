import React from "react";
import { DataHelper } from "../Helpers/DataHelper";
import { UploadDocumentation } from "../Helpers/UploadDocumentation";

export class UploadHomeWindow extends React.Component<
    {
        dataClient: DataHelper,
        uploadButtonClicked: ()=> void
        //Properties go here
    },
    {
        //State goes here
    }
> {

    

    constructor(props: any) {
        super(props);
        this.state = {
            //Initialize ALL state variables here
        }
    }
    
    componentDidMount(): void {
        //Put stuff here that you want to run the first time the component is loaded.
    }


    render() {
        //THis is where we return our HTML for the UI
        

        return (
            <>
                <div className="uploadUserScreen">
                    <div className="UploadGuide">
                        <div className="dashboardHeader">
                            <h1>Uploading Data to Your Study: A Step-by-Step Guide</h1>
                        </div>
                        <div className="textContainer">
                            <UploadDocumentation></UploadDocumentation>
                        </div>
                    </div>
                    

                    <div className="uploadButtonWrap">
                        <button className="primaryButton" onClick={()=>{this.props.uploadButtonClicked();}}>Upload</button>
                    </div>
                </div>
            </>
        );
    }
}
